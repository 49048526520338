<template>
   <v-snackbar
      :class="{ 'mb-1': $vuetify.breakpoint.smAndDown, 'mb-5': $vuetify.breakpoint.mdAndUp }"
      :value="$store.getters.hasMessage" @input="closeNotification"
   >
      <div class="notification">
         {{ $store.state.note }}
      </div>
      <template v-slot:action="{ attrs }">
         <v-btn color="secondary" class="mr-2 px-9" depressed v-bind="attrs" @click="closeNotification">
            OK
         </v-btn>
      </template>
   </v-snackbar>
</template>

<script>
export default {
   methods: {
      closeNotification () {
         this.$store.commit('resetNotification')
      },
   },
}
</script>

<style lang="scss" scoped>
::v-deep .v-snack__wrapper {
   background: #424242;
   padding: 12px 8px;
}
.notification {
   font-size: 1.0rem;
}
</style>
